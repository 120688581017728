import React, {useState} from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [emailSent, setEmailSent] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);

  const onSubmit = (data, e) => {
    const postUrl = 'https://api.web3forms.com/submit';
    axios({method: 'POST', url: postUrl, data: {access_key: 'bb74abcf-c102-46a5-8a10-e77480d94085', name: data.name, email: data.email, subject: data.subject, message: data.comment}})
    .then( (response) => {
      e.target.reset();
      setEmailSent(true);
      setResponseSuccess(true);
    })
    .catch( (error) => {
      setEmailSent(true);
      setResponseSuccess(false);
    });
  };

  return (
    <div>
      {!emailSent ?
       <form className="contct-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                {...register("name", { required: true })}
              />
              <label className="form-label">Name</label>
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
              />
              <label className="form-label">Email</label>
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                {...register("subject", { required: true })}
              />
              <label className="form-label">Subject</label>
              {errors.subject && (
                <span className="invalid-feedback">Subject is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                rows="4"
                className="form-control"
                placeholder="Type comment"
                {...register("comment", { required: true })}
              ></textarea>
              <label className="form-label">Comment</label>
              {errors.comment && (
                <span className="invalid-feedback">Comment is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="btn-bar">
              <button className="px-btn px-btn-theme">Send your message</button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
          : 
            <div>
              {responseSuccess ? 
                <div>
                <strong>Thank you!</strong>
                <br></br><br></br>
                Your message has been sent. I look forward to connecting.
                <br></br><br></br>
                </div>
              : 
                <div>
                <strong>Sorry, there was an error.</strong>
                <br></br><br></br>
                Please revise and try again.
                <br></br><br></br>
                <a className="px-btn px-btn-theme" href="#contact" onClick={()=>setEmailSent(false)}>
                Back
              </a>
                </div>
              }
            </div>
          }
    </div>

  );
};

export default Contact;
