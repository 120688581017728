import React from "react";

const About = () => {
  return (
    <div>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 my-3">
          <div className="img-box dark-img-box">
            <img src="img/about/about-dan.jpg" alt="About Dan" />
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <h3>Dan Rutledge</h3>
            <h5>
              A wannabe <span className="color-theme">filmmaker</span> turned unlikely <span className="color-theme">tech entrepreneur</span>
            </h5>
            <p>
            Dan studied filmmaking in college and pursued a career writing and directing for about 15 years. The winding journey involved grad school, a stint in Los Angeles, and producing a variety of small projects that you've definitely never heard of.
          </p>
          <p>Since filmmaking never paid the bills, Dan worked a "backup" job doing web development. Over the years, Dan ended up providing high-end design and development for numerous national clients and transitioned into a career as an application developer. 
          </p>
          <p>
          In 2008, Dan helped found the tech startup SignUpGenius and served as CEO and CTO for over ten years.
Together with his wife Angel, Dan co-led the company as it grew to serve over 100 million users per year. SignUpGenius was recognized multiple times as one of the “Best Employers in North Carolina” by Business North Carolina magazine. In 2016, SignUpGenius was named the Small Tech Company of the Year at the NC Tech Awards and in 2017, Dan was a finalist for Tech Executive of the Year by the NC Technology Association. In 2017, the majority stake of SignUpGenius was sold to Providence Strategic Growth and Dan stepped down from leadership in 2019.
            </p>
            <p>
            Dan currently lives in Charlotte, NC where he is working on a new startup with his wife Angel. He is a member of Christ Central Church and has four grown children. Dan is a Phi Beta Kappa graduate of the University of Michigan with a Master of Arts from Regent University. He still makes videos for fun.
            </p>
            {/* <div className="row about-list">
              <div className="col-md-6">
                <div className="media">
                  <label>Birthday</label>
                  <p>4th april 1991</p>
                </div>
                <div className="media">
                  <label>Age</label>
                  <p>22 Yr</p>
                </div>
                <div className="media">
                  <label>Residence</label>
                  <p>Canada</p>
                </div>
                <div className="media">
                  <label>Address</label>
                  <p>California, USA</p>
                </div>
              </div>*/}
              {/* End .col */}

              {/* <div className="col-md-6">
                <div className="media">
                  <label>E-mail</label>
                  <p>info@domain.com</p>
                </div>
                <div className="media">
                  <label>Phone</label>
                  <p>820-885-3321</p>
                </div>
                <div className="media">
                  <label>Skype</label>
                  <p>skype.0404</p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Available</p>
                </div>
              </div>  */}
              {/* End .col */}
            </div>
            {/* End .row */}
            <div className="justify-content-center" align="center">
              <br />
              <a className="px-btn px-btn-theme" href="#technology">
                Technology
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a className="px-btn px-btn-theme" href="#art">
                Art
              </a>
            </div>
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
  );
};

export default About;
