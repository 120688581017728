import React from "react";

const ServiceContent = [
  {
    icon: "ti-ruler-pencil",
    no: "01",
    img: "./img/tech/Meetify-logo.png",
    url: "https://meetify.com",
    service: "Aug 2022 - Present",
    company: "Meetify",
    title: "CO-FOUNDER, CEO",
    desc: `Provide leadership, product design, technology architecture, graphic design, and full-stack development for a startup aiming to disrupt the meeting scheduling space.`,
  },
  {
    icon: "ti-image",
    no: "02",
    url: "https://www.signupgenius.com",
    img: "/img/tech/SignUpGenius.png",
    service: "Oct 2008 - Jun 2019",
    company: "SignUpGenius",
    title: "CO-FOUNDER, CEO, CTO",
    desc: `Provided strategic leadership and acted as the chief technology architect for the world's largest volunteer management site serving over 100 million users per year.`,
  },
  {
    icon: "ti-layout",
    no: "03",
    img: "/img/tech/Skylark.png",
    service: "Mar 2005 - 2010",
    company: "Skylark",
    title: "VP OF FILM & INTERNET",
    desc: `Provided application development services for TITAN Technology Partners including creation of the LMS portal Lavoroh.com and TITAN ReportCenter.`,
  },
  {
    icon: "ti-layout",
    no: "04",
    img: "/img/tech/Spotlight-Media.png",
    service: "1997 - Mar 2005",
    company: "Spotlight Media",
    title: "FOUNDER",
    desc: `Provided application development and graphic design for numerous national clients including: Sona Med Spa, Block Imaging, At Home America, and the Media Access Group.`,
  },
];

const ServiceTwo = () => {
  return (
    <>
      <div className="row gy-4">
        {ServiceContent.map((val, i) => (
          <div className="col-sm-6 col-lg-6" key={i}>
            <div className="feature-box-02">
              {/* <div className="icon">
                <i className={val.icon}></i>
              </div> */}
              <div className="feature-image">
                {val.url ? 
                <a href={val.url} target="_blank" rel="noopener noreferrer">
                  <img
                  src={val.img}
                  alt={val.company}
                  role="button"
                  width="240"
                  border="0"
                />
                </a>
                : 
                <img
                  src={val.img}
                  alt={val.company}
                  role="button"
                  width="240"
                  border="0"
                />
                }
              </div>
              <h6>
                {/* <span>{val.no}.</span>  */}
                {val.service}
              </h6>
              <h5>{val.title}</h5>
              <p>{val.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceTwo;
